import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'

import useConsult from '../Firebase/Hooks/useConsult'

import Contactos from '../Components/Contactos'

import img11 from '../Images/11.png'
import img11_1 from '../Images/11-1.png'
import img12 from '../Images/12.png'
import Formulario from '../Components/Formulario'

const Contacto = () => {
  const {
    collection: { especialidades }
  } = useSelector((state) => state.firestore)

  useConsult([
    {
      consulta: 'obtenerRealTime',
      collectionName: 'especialidades'
    }
  ])

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img11_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img11} alt="Africare" />
      )}

      <Container
        sx={{ maxWidth: { xs: '370px', sm: '700px', md: 'lg', lg: 'lg' } }}
      >
        <section>
          <h2 className="titulo-5" style={{ margin: '0', textAlign: 'center' }}>
            Contacto
          </h2>
          <div style={{ padding: '1px 0 70px 0', marginTop: '50px' }}>
            <Contactos />
          </div>
        </section>

        <section>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10561.321225752003!2d-103.43635206277536!3d20.629226573358768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac4301726e6d%3A0x7df236fbe0f0ad5a!2sCl%C3%ADnica%20Veterinaria%20Africam!5e0!3m2!1ses!2smx!4v1696978053778!5m2!1ses!2smx"
            style={{ border: 'none', width: '100%', height: '350px' }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>

        <section style={{ margin: '50px auto' }}>
          <Grid container spacing={5}>
            <Grid xs={12} lg={5}>
              <div style={{ textAlign: 'center' }}>
                <img
                  className="imagen-v-movil"
                  src={img12}
                  style={{ width: '100%' }}
                />
              </div>
            </Grid>
            <Grid xs={12} lg={7}>
              <div style={{ marginBottom: '40px' }}>
                <p className="titulo-2 texto-posicion">
                  ¿Necesitas una consulta?
                </p>
                <p className="texto-posicion">
                  Escríbenos por WhatsApp o llena el siguiente formulario.{' '}
                  <br />
                  Nos pondremos en contacto lo antes posible
                </p>
              </div>
              {especialidades && (
                <Formulario especialidades={especialidades.docs} />
              )}
            </Grid>
          </Grid>
        </section>
      </Container>
    </Fragment>
  )
}

export default Contacto
