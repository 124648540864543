import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container'

import useConsult from '../Firebase/Hooks/useConsult'

import Especialidad from '../Components/Especialidad'
import Especialidades from '../Components/Especialidades'

import Formulario from '../Components/Formulario'

const Servicio = () => {
  const { url } = useParams()

  const {
    collection: { especialidades }
  } = useSelector((state) => state.firestore)

  useConsult([
    {
      consulta: 'obtenerRealTime',
      collectionName: 'especialidades'
    }
  ])

  const [flag, setFlag] = useState(true)
  const [servicioData, setServicioData] = useState({})

  useEffect(() => {
    if (especialidades) {
      let filtro = especialidades.docs.filter(
        (especialidad) => especialidad.url_redireccion === url
      )

      setServicioData(filtro[0])
      setFlag(false)
      window.scrollTo(0, 0)
    }
  }, [especialidades, url])

  function createMarkup(descripcion) {
    return { __html: descripcion }
  }

  return (
    <Fragment>
      {!flag && (
        <div>
          {window.innerWidth <= 990 ? (
            <img
              style={{ marginTop: '85px' }}
              className="imagen_sobrepuesta"
              src={servicioData.imagenes.imagenes[5]}
              alt={servicioData.nombre}
            />
          ) : (
            <img
              style={{ marginTop: '140px' }}
              className="imagen_sobrepuesta"
              src={servicioData.imagenes.imagenes[1]}
              alt={servicioData.nombre}
            />
          )}

          <Container
            sx={{
              maxWidth: { xs: 'lg', sm: '600px', md: '600px', lg: 'lg' },
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <section>
              <Grid sx={{ justifyContent: 'center' }} container spacing={2}>
                <Grid xs={12} lg={5}>
                  {window.innerWidth <= 990 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '-75px'
                      }}
                    >
                      <img
                        style={{
                          maxWidth: '350px',
                          margin: 'auto'
                        }}
                        className="imagen_sobrepuesta"
                        src={servicioData.imagenes.imagenes[6]}
                        alt={servicioData.nombre}
                      />
                    </div>
                  ) : (
                    <p className="titulo-2 texto-posicion">
                      {servicioData.nombre}
                    </p>
                  )}

                  <div
                    className="texto-3"
                    dangerouslySetInnerHTML={createMarkup(
                      servicioData.descripcion_interna
                    )}
                  />
                  <div className="posicion-boton">
                    <Link
                      key="agenda una cita"
                      style={{
                        textDecoration: 'none',
                        fontSize: '15px',
                        padding: '14px 25px'
                      }}
                      className="boton-2 "
                      to="/contacto"
                    >
                      Agenda una cita
                    </Link>
                  </div>
                </Grid>
                <Grid xs={6} lg={3}>
                  <img
                    src={servicioData.imagenes.imagenes[2]}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Grid>
                <Grid xs={6} lg={3}>
                  <img
                    src={servicioData.imagenes.imagenes[3]}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Grid>
              </Grid>
            </section>
          </Container>

          <section className="fondo-3" style={{ marginTop: '50px' }}>
            <Container
              sx={{
                maxWidth: { xs: '350px', sm: '600px', md: '600px', lg: 'lg' }
              }}
              style={{ padding: '50px 0' }}
            >
              <p className="titulo-1">Especialidad</p>
              <p className="titulo-2">{servicioData.especialidad.titulo}</p>
              <div
                className="texto-3"
                style={{
                  maxWidth: '500px',
                  textAlign: 'center',
                  margin: 'auto'
                }}
                dangerouslySetInnerHTML={createMarkup(
                  servicioData.descripcion_especialidad
                )}
              />
              <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                {servicioData.especialidad.caracteristicas.map(
                  (especialidad) => (
                    <Grid xs={12} lg={6} style={{ textAlign: 'center' }}>
                      <Especialidad data={especialidad} />
                    </Grid>
                  )
                )}
              </Grid>
            </Container>
          </section>

          <section style={{ margin: '50px auto' }}>
            <Container
              sx={{
                maxWidth: { xs: '370px', sm: '600px', md: '600px', lg: 'lg' }
              }}
            >
              <Grid container spacing={5}>
                <Grid xs={12} lg={5}>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      className="imagen-v-movil"
                      src={servicioData.imagenes.imagenes[4]}
                      style={{ width: '100%' }}
                    />
                  </div>
                </Grid>
                <Grid xs={12} lg={7}>
                  <div style={{ marginBottom: '40px' }}>
                    <p className="titulo-2 texto-posicion">
                      ¿Necesitas una consulta?
                    </p>
                    <p className="texto-posicion">
                      Escríbenos por WhatsApp{' '}
                      <br className="invisible visible-movil visible-tablet" />o
                      llena el siguiente formulario. <br />
                      Nos pondremos en contacto lo antes posible
                    </p>
                  </div>
                  <Formulario especialidades={especialidades.docs} />
                </Grid>
              </Grid>
            </Container>
          </section>

          <Container maxWidth="lg">
            <p className="titulo-2 texto-posicion">Otros Servicios</p>
            <div className="contenedor-1" style={{ margin: '30px 0 50px 0' }}>
              {especialidades &&
                especialidades.docs.map(
                  (especialidad) =>
                    especialidad.url_redireccion !== url && (
                      <Especialidades data={especialidad} />
                    )
                )}
            </div>
          </Container>
        </div>
      )}
    </Fragment>
  )
}

export default Servicio
