import React, { Fragment, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'

function Galerias({ images }) {
  const [view, setView] = useState(true)

  console.log({ view, images })

  return (
    <Fragment>
      {view !== true && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            zIndex: '999',
            top: '0',
            left: '0',
            background: '#000000cf',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => {
            setView(true)
          }}
        >
          <img
            src={images[view]}
            alt="AFRICARE imagen"
            style={{ width: '70%', maxWidth: '600px' }}
          />
        </div>
      )}
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={index} xs={4}>
            <Card>
              <CardMedia
                onClick={() => {
                  setView(index)
                }}
                component="img"
                image={image}
                alt="AFRICARE imagen"
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

export default Galerias
