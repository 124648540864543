import React, { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// styles
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
// inputs
import {
  ValidationInput,
  ValidationTextField,
  Telefono,
  SelectInput
} from '../Styles/Styles'

// ejemplo
import { useTheme } from '@mui/material/styles'
import { FormControl, InputLabel } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const Formulario = ({ especialidades }) => {
  const [state, setState] = useState({
    nombre: '',
    correo: '',
    telefono: ''
  })

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const enviar = (e) => {
    e.preventDefault()
    console.log({ state, especialidades })
  }

  // ejemplo
  const theme = useTheme()
  const [personName, setPersonName] = useState([])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    }
  }
  // ejmeplo

  return (
    <section>
      <form onSubmit={enviar}>
        <Grid container spacing={2} rowSpacing={6}>
          <Grid xs={12} md={12} lg={6}>
            <ValidationTextField
              onChange={leerDato}
              placeholder="Escribe tu nombre"
              name="nombre"
              variant="outlined"
              label="Tu nombre *"
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <ValidationTextField
              onChange={leerDato}
              placeholder="Escribe tu e-mail"
              name="correo"
              variant="outlined"
              label="Tu e-mail *"
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              required
            />
          </Grid>

          <Grid xs={12} md={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel style={{ top: '-16px' }} shrink={true} id="telefono">
                Tu celular *
              </InputLabel>
              <ValidationInput
                onChange={leerDato}
                name="telefono"
                id="telefono"
                placeholder="Escribe tu celular"
                value={state.telefono}
                inputComponent={Telefono}
                required
              />
            </FormControl>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel
                style={{ top: '-16px' }}
                shrink={true}
                id="demo-simple-select-label-1"
              >
                Selecciona un servicio *
              </InputLabel>
              <Select
                multiplelabelId="demo-simple-select-label-1"
                id="demo-simple-select-1"
                displayEmpty={true}
                value={personName}
                onChange={handleChange}
                input={<SelectInput />}
                // input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Servicios</span>
                  }

                  return selected.join(', ')
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <span>Servicios</span>
                </MenuItem>
                {especialidades.map((especialidad) => (
                  <MenuItem
                    key={especialidad.nombre}
                    value={especialidad.nombre}
                    style={getStyles(especialidad.nombre, personName, theme)}
                  >
                    {especialidad.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={12}>
            <ValidationTextField
              onChange={leerDato}
              label="Mensaje (opcional)"
              placeholder="Escribe un mensaje"
              InputLabelProps={{
                shrink: true
              }}
              name="mensaje"
              variant="outlined"
              multiline
              fullWidth
              required
              rows={4}
            />
          </Grid>

          <Grid xs={12}>
            <div className="centrar-movil-2">
              <Button type="submit" className="boton-enviar">
                Enviar
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </section>
  )
}

export default Formulario
