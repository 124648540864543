import React from "react";
import puntuacion from "../Images/Vector.png";

function createMarkup(descripcion) {
  return { __html: descripcion };
}

function Testimonios({ data }) {
  return (
    <div style={{ margin: "10px" }}>
      <img className="imagen-testimonios" src={data.url}></img>
      <div className=" texto-centrado contenedor-testimonios">
        <p className="titulo-testimonios">{data.titulo}</p>
        <img style={{ color: "#0254BF" }} src={puntuacion}></img>
        <div
          className="texto-3"
          dangerouslySetInnerHTML={createMarkup(data.contenido)}
        ></div>
        <p style={{ fontSize: "16px" }} className="titulo-testimonios">
          {data.nombre}
        </p>
        <p style={{ fontSize: "14px" }} className="titulo-testimonios">
          {data.puesto}
        </p>
      </div>
    </div>
  );
}

export default Testimonios;
