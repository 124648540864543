import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'

import useConsult from '../Firebase/Hooks/useConsult'

import NosostrosColapsible from '../Components/NosostrosColapsible'

import img4 from '../Images/4.png'
import img4_1 from '../Images/4-1.png'
import img5 from '../Images/5.png'

import icono6 from '../Images/Icons/6.svg'
import icono7 from '../Images/Icons/7.svg'
import icono8 from '../Images/Icons/8.svg'
import icono9 from '../Images/Icons/9.svg'
import Equipo from '../Components/Equipo'

const Nosotros = () => {
  const {
    collection: { empleados }
  } = useSelector((state) => state.firestore)

  useConsult([
    {
      consulta: 'obtenerRealTime',
      collectionName: 'empleados'
    }
  ])

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img4_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img4} alt="Africare" />
      )}

      <Container
        sx={{ maxWidth: { xs: 'lg', sm: '700px', md: 'lg', lg: 'lg' } }}
      >
        <p className="texto-2" style={{ textAlign: 'center' }}>
          Somos el equipo de profesionales apasionados <br />
          por la salud y bienestar de tus mascotas.
        </p>
        <section style={{ marginTop: '30px' }}>
          <NosostrosColapsible />
        </section>

        <section style={{ marginTop: '50px' }}>
          <Grid container>
            <Grid sm={12} md={6} lg={6}>
              <p className="titulo-1 alineacion-texto">NUESTROS SERVICIOS</p>
              <p className="titulo-2 alineacion-texto">Sobre AFRICARE</p>
              <p className="alineacion-texto">
                Nuestro enfoque se basa en una atención personalizada y
                compasiva, donde nos tomamos el tiempo para escuchar tus
                inquietudes, explicarte los diagnósticos y tratamientos de
                manera clara y brindarte opciones de cuidado adecuadas para tus
                mascotas.
              </p>
              <div
                className="centrar-movil-1"
                style={{ display: 'flex', gap: '15%' }}
              >
                <ul className="lista-servicios">
                  <li>LabPet</li>
                  <li>Africare</li>
                  <li>Fisiocan</li>
                  <li>CardioVet</li>
                </ul>
                <ul className="lista-servicios">
                  <li>Africamgroomers</li>
                  <li>African</li>
                  <li>Africat</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={12} md={6} lg={6}>
              <img
                src={img5}
                alt="Africare"
                style={{ width: '90%', height: 'auto' }}
              />
            </Grid>
          </Grid>
        </section>
      </Container>

      <section
        className="fondo-1"
        style={{ padding: '1px 0 70px 0', marginTop: '50px' }}
      >
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <p className="titulo-1">CONSULTAS</p>
          <p style={{ marginBottom: '30px' }} className="titulo-2">
            ¿Cómo es el proceso{' '}
            <br className="invisible visible-movil visible-tablet" /> de una
            consulta?
          </p>

          <Container maxWidth="lg">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid
                sx={{ marginTop: { xs: '20px' } }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <img src={icono6} alt="Contactanos" />
                <div style={{ padding: '0 25px' }}>
                  <p style={{ marginBottom: '0' }} className="titulo-contacto">
                    Contáctanos
                  </p>
                  <p style={{ margin: '0' }}>
                    Comunícate a través de nuestros <br /> canales de contacto.
                  </p>
                </div>
              </Grid>
              <Grid
                sx={{ marginTop: { xs: '20px' } }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <img src={icono7} alt="Agenda una cita" />
                <div style={{ padding: '0 25px' }}>
                  <p className="titulo-contacto" style={{ marginBottom: '0' }}>
                    Agenda una cita
                  </p>
                  <p style={{ margin: '0' }}>
                    Te pediremos información importante,{' '}
                    <br className="invisible visible-movil visible-tablet" />{' '}
                    como el nombre, edad y los síntomas que experimente tu
                    mascota.
                  </p>
                </div>
              </Grid>
              <Grid
                sx={{ marginTop: { xs: '20px' } }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <img src={icono8} alt="Recibe la mejor atención" />
                <div style={{ padding: '0 25px' }}>
                  <p className="titulo-contacto" style={{ marginBottom: '0' }}>
                    Recibe la mejor atención
                  </p>
                  <p style={{ margin: '0' }}>
                    Llega a nuestra clínica en la fecha y hora programada y
                    recibe el mejor tratamiento.
                  </p>
                </div>
              </Grid>
              <Grid
                sx={{ marginTop: { xs: '20px' } }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <img src={icono9} alt="Seguimiento" />
                <div style={{ padding: '0 25px' }}>
                  <p className="titulo-contacto" style={{ marginBottom: '0' }}>
                    Seguimiento
                  </p>
                  <p style={{ margin: '0' }}>
                    Estaremos disponibles para brindarte asesoramiento o
                    responder cualquier{' '}
                    <br className="invisible visible-movil visible-tablet" />{' '}
                    pregunta en cualquier momento.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </section>

      <section style={{ margin: '50px 0' }}>
        <Container>
          <p className="titulo-1">NUESTROS EXPERTOS</p>
          <h1 className="titulo-2">Conoce a nuestro equipo</h1>
          <Grid container spacing={2}>
            {empleados &&
              empleados.docs.map((equipo, index) => (
                <Grid xs={6} sm={6} md={3}>
                  <Equipo data={equipo} index={index} />
                </Grid>
              ))}
          </Grid>
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Link
              key="agenda una cita"
              style={{
                textDecoration: 'none',
                fontSize: '15px',
                padding: '14px 25px'
              }}
              className="boton-2"
              to="/nosotros"
            >
              Ver más
            </Link>
          </div>
        </Container>
      </section>
    </Fragment>
  )
}

export default Nosotros
