import React, { Fragment } from "react";

function Equipo({ data, index }) {
  const colorClass = `equipo-color-${(index % 3) + 1}`;

  return (
    <Fragment>
      <div className="container-equipo">
        <div className={`box ${colorClass} direccion-${index % 2}`}>
          <div className="content">
            <img src={data.url} alt={data.nombre} />
          </div>
        </div>
      </div>
      <h3
        style={{
          fontFamily: "Poppins",
          marginBottom: "0",
          textAlign: "center",
        }}
        className={colorClass}
      >
        {data.nombre}
      </h3>
      <p style={{ marginTop: "0" }} className="texto-centrado">
        {data.puesto}
      </p>
    </Fragment>
  );
}

export default Equipo;
