import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import useConsult from "../Firebase/Hooks/useConsult";

import DerivacionesFormulario from "../Components/DerivacionesFormulario";

import img8 from "../Images/8.png";
import img8_1 from "../Images/8-1.png";
import img9 from "../Images/9.png";

const Derivaciones = () => {
  const {
    collection: { especialidades },
  } = useSelector((state) => state.firestore);

  useConsult([
    {
      consulta: "obtenerRealTime",
      collectionName: "especialidades",
    },
  ]);

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img8_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img8} alt="Africare" />
      )}

      <Container
        sx={{ maxWidth: { xs: "lg", sm: "700px", md: "lg", lg: "lg" } }}
      >
        <section>
          <h2 className="titulo-5" style={{ margin: "0", textAlign: "center" }}>
            Derivaciones
          </h2>
          <p
            className="texto-2"
            style={{ textAlign: "center", maxWidth: "600px", margin: "auto" }}
          >
            En nuestra clínica veterinaria, entendemos que cada caso es único y
            a veces pueden surgir situaciones en las que se requiere un enfoque
            especializado.
            <br />
            <br />
            Es por eso que ofrecemos un servicio de derivaciones para otros
            colegas veterinarios que necesiten apoyo adicional en el manejo de
            casos complejos.
          </p>

          <Grid container spacing={5} style={{ marginTop: "50px" }}>
            <Grid
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
              sx={{ order: { xs: 2, sm: 2, md: 1 } }}
            >
              <p className="texto-2 alineacion-texto">
                Nuestro equipo de especialistas está disponible para trabajar en
                estrecha colaboración, brindando asesoramiento, diagnóstico y
                tratamiento avanzado en áreas especializadas.
                <br />
                <br />
                Ya sea que se trate de un caso de medicina interna,
                dermatología, oftalmología, cardiología, oncología, ortopedia u
                otras especialidades, estamos comprometidos en proporcionar un
                enfoque integral y multidisciplinario para el cuidado de los
                animales.
                <br />
                <br />
                Escríbenos por WhatsApp o llena el siguiente formulario. Nos
                pondremos en contacto lo antes posible
              </p>
            </Grid>
            <Grid sm={12} md={6} lg={6} sx={{ order: { xs: 1, sm: 1, md: 2 } }}>
              <img src={img9} alt="HerGon" style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </section>

        <section style={{ margin: "50px 10px" }}>
          {especialidades && (
            <DerivacionesFormulario especialidades={especialidades.docs} />
          )}
        </section>
      </Container>
    </Fragment>
  );
};

export default Derivaciones;
