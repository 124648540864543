import React, { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// styles
import Button from '@mui/material/Button'
// inputs
import {
  ValidationInput,
  ValidationTextField,
  Telefono,
  SelectInput
} from '../Styles/Styles'

// ejemplo
import { useTheme } from '@mui/material/styles'
import { FormControl, InputLabel } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const DerivacionesFormulario = ({ especialidades }) => {
  const [state, setState] = useState({
    nombre: '',
    correo: '',
    telefono: ''
  })

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const enviar = (e) => {
    e.preventDefault()
    // console.log({ state, especialidades });
  }

  // ejemplo
  const theme = useTheme()
  const [personName, setPersonName] = useState([])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    }
  }
  // ejmeplo

  return (
    <section>
      <form onSubmit={enviar}>
        <Grid
          style={{ marginBottom: '10px' }}
          container
          spacing={2}
          rowSpacing={6}
        >
          <Grid xs={12} md={12} lg={4}>
            <ValidationTextField
              onChange={leerDato}
              placeholder="Nombre del médico veterinario"
              name="nombre_medico"
              variant="outlined"
              label="Nombre del médico veterinario"
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid xs={12} md={12} lg={4}>
            <ValidationTextField
              onChange={leerDato}
              placeholder="Nombre de la clínica"
              name="nombre_medico"
              variant="outlined"
              label="Clínica veterinaria"
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid xs={12} md={12} lg={4}>
            <ValidationTextField
              onChange={leerDato}
              placeholder="Nombre del animalito"
              name="nombre_medico"
              variant="outlined"
              label="Nombre del paciente"
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              required
            />
          </Grid>

          <Grid xs={12} md={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel
                style={{ top: '-16px' }}
                shrink={true}
                id="demo-simple-select-label-1"
              >
                Especialidad
              </InputLabel>
              <Select
                multiplelabelId="demo-simple-select-label-1"
                id="demo-simple-select-1"
                displayEmpty={true}
                value={personName}
                onChange={handleChange}
                input={<SelectInput />}
                // input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Seleccione la especialidad</span>
                  }

                  return selected.join(', ')
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <span>Seleccione la especialidad</span>
                </MenuItem>
                {especialidades.map((especialidad) => (
                  <MenuItem
                    key={especialidad.nombre}
                    value={especialidad.nombre}
                    style={getStyles(especialidad.nombre, personName, theme)}
                  >
                    {especialidad.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={12} md={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel style={{ top: '-16px' }} shrink={true} id="telefono">
                Teléfono
              </InputLabel>
              <ValidationInput
                onChange={leerDato}
                name="telefono"
                id="telefono"
                value={state.telefono}
                inputComponent={Telefono}
                placeholder="Teléfono de contacto"
                required
              />
            </FormControl>
          </Grid>

          <Grid xs={12} md={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel
                style={{ top: '-16px' }}
                shrink={true}
                id="telefono_extra"
              >
                Dato extra
              </InputLabel>
              <ValidationInput
                onChange={leerDato}
                name="telefono_extra"
                id="telefono_extra"
                value={state.telefono}
                placeholder="Teléfono de contacto"
                inputComponent={Telefono}
                required
              />
            </FormControl>
          </Grid>

          <Grid xs={12}>
            <ValidationTextField
              onChange={leerDato}
              label="Descripción"
              placeholder="Mensaje"
              InputLabelProps={{
                shrink: true
              }}
              name="mensaje"
              variant="outlined"
              multiline
              fullWidth
              required
              rows={2}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" className="boton-enviar">
            Enviar
          </Button>
        </div>
      </form>
    </section>
  )
}

export default DerivacionesFormulario
