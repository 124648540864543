import React from 'react';

import Container from '@mui/material/Container';


const Aviso = () => {
    return (
        <Container maxWidth="lg">
            <h1>Aviso de privacidad</h1>
        </Container>
    );
};

export default Aviso;