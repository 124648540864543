import React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'

import icono1 from '../Images/Icons/1.png'
import icono2 from '../Images/Icons/2.png'
import icono3 from '../Images/Icons/3.png'
import icono4 from '../Images/Icons/4.png'

const Contactos = () => {
  return (
    <Container>
      <Grid container columnSpacing={3} rowSpacing={5}>
        <Grid
          xs={12}
          sm={6}
          lg={3}
          sx={{ padding: { xs: '24px 50px', sm: '24px 20px' } }}
        >
          <div className="contacto-color-1 contacto-margin">
            <div className="fondo-icono">
              <img src={icono1} alt="" />
            </div>
            <p style={{ marginBottom: '0' }} className="titulo-contacto">
              Teléfono
            </p>
            <a
              style={{ textDecoration: 'none', color: '#1c103b' }}
              target="_blanck"
              href="tel:3331243084"
            >
              <p style={{ margin: '0' }}>33 3124 3084</p>
            </a>
            <a
              style={{ textDecoration: 'none', color: '#1c103b' }}
              target="_blanck"
              href="tel:3331243084"
            >
              <p style={{ marginTop: '0' }}>33 2386 0821</p>
            </a>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          lg={3}
          sx={{ padding: { xs: '24px 50px', sm: '24px 20px' } }}
        >
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono2} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: '0' }}>
              Urgencias
            </p>
            <a
              style={{ textDecoration: 'none', color: '#1c103b' }}
              target="_blanck"
              href="tel:3313114196"
            >
              <p style={{ margin: '0' }}>33 1311 4196</p>
            </a>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          lg={3}
          sx={{ padding: { xs: '24px 50px', sm: '24px 20px' } }}
        >
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono3} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: '0' }}>
              Dirección
            </p>
            <a
              style={{ textDecoration: 'none', color: '#1c103b' }}
              target="_blanck"
              href="https://maps.app.goo.gl/EpvR5ybXMgjqQVRC8"
            >
              <p style={{ margin: '0' }}>Av. Galileo Galilei #4008,</p>
              <p style={{ marginTop: '0' }}>Arboledas, Zapopan, Jal.</p>
            </a>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          lg={3}
          sx={{ padding: { xs: '24px 50px', sm: '24px 20px' } }}
        >
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono4} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: '0' }}>
              Horario de trabajo
            </p>
            <p style={{ margin: '0' }}>24 horas</p>
            <p style={{ marginTop: '0' }}>365 dias del año</p>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Contactos
