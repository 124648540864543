import React from 'react'

import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import ListItemText from '@mui/material/ListItemText'
import logoAfriCare from '../Images/Icons/logo-AfriCare.png'
import instagram from '../Images/Icons/logo-instagram.png'
import facebook from '../Images/Icons/logo-facebook.png'
import whatsapp from '../Images/Icons/logo-whatsapp.png'

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-borde" />
      <Container
        sx={{ maxWidth: { xs: 'lg', sm: '600px', md: '600px', lg: 'lg' } }}
      >
        <Grid container spacing={2} columns={24}>
          <Grid xs={24} md={8} style={{ textAlign: 'center' }}>
            <img src={logoAfriCare} alt="" className="img-footer" />
            <br />
            <span className="titulo-footer">Av. Galileo Galilei #4008,</span>
            <br />
            <span className="titulo-footer">Arboledas, Zapopan, Jal.</span>
            <div style={{ marginTop: '20px' }}>
              <span style={{ margin: '0 10px' }}>
                <a
                  target="_blanck"
                  href="https://www.facebook.com/veterinariafricam"
                >
                  <img
                    style={{ width: '27px' }}
                    src={facebook}
                    alt="logo facebook"
                  />
                </a>
              </span>
              <span style={{ margin: '0 10px' }}>
                <a
                  target="_blanck"
                  href="https://www.instagram.com/africarehospitalveterinario"
                >
                  <img
                    style={{ width: '27px' }}
                    src={instagram}
                    alt="logo instagram"
                  />
                </a>
              </span>
              <span style={{ margin: '0 10px' }}>
                <img
                  style={{ width: '27px' }}
                  src={whatsapp}
                  alt="logo whatsapp"
                />
              </span>
            </div>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil">
            <p className="titulo-footer-2">AFRICARE</p>
            <Link to={'/'}>
              <ListItemText
                primary={'Inicio'}
                className="margin-links-footer"
              />
            </Link>
            <Link to={'/contacto'}>
              <ListItemText
                primary={'Agenda una cita'}
                className="margin-links-footer"
              />
            </Link>
            <Link to={'/aviso-de-privacidad'}>
              <ListItemText
                primary={'Aviso de privacidad'}
                className="margin-links-footer"
              />
            </Link>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil-2">
            <p className="titulo-footer-2">Especialidades</p>
            <Link to={'/servicio/africare'}>
              <ListItemText
                primary={'AfriCare'}
                className="margin-links-footer"
              />
            </Link>
            <Link to={'/servicios'}>
              <ListItemText
                primary={'Servicios'}
                className="margin-links-footer"
              />
            </Link>{' '}
            <Link to={'/testimonios'}>
              <ListItemText
                primary={'Testimonios'}
                className="margin-links-footer"
              />
            </Link>
          </Grid>{' '}
          <Grid xs={12} md={4} className="grid-movil">
            <p className="titulo-footer-2">Proyectos</p>
            <Link to={'/derivaciones'}>
              <ListItemText
                primary={'Derivaciones'}
                className="margin-links-footer"
              />
            </Link>
            <Link to={'/asociaciones'}>
              <ListItemText
                primary={'Asociaciones'}
                className="margin-links-footer"
              />
            </Link>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil-2">
            <p className="titulo-footer-2">Contacto</p>
            <div className="texto-footer">
              <a
                style={{
                  textDecoration: 'none',
                  color: '#ffffff'
                }}
                target="_blanck"
                href="tel:3331243084"
              >
                33 3124 3084
              </a>
              <br />
              <a
                style={{
                  textDecoration: 'none',
                  color: '#ffffff',
                  display: 'block'
                }}
                target="_blanck"
                href="tel:3323860821"
                className="margin-links-footer"
              >
                33 2386 0821
              </a>
              <p style={{ margin: '20px 0 0 0' }}>Emergencias</p>
              <a
                style={{ textDecoration: 'none', color: '#ffffff' }}
                target="_blanck"
                href="tel:3313114196"
              >
                33 1311 4196
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="copyright">
        <p>
          Africam© Todos los derechos reservados.{' '}
          <br className="invisible visible-movil visible-tablet" /> Copyright{' '}
          {new Date().getFullYear()}
        </p>
      </div>
      <div className="page-footer-borde" />
    </footer>
  )
}

export default Footer
