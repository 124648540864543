import React from 'react'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import img from '../Images/nosotros.png'
const Nosostros = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} sx={{ order: { xs: 2, sm: 2, md: 1 } }}>
          <p className="titulo-1 alineacion-texto">NOSOTROS</p>
          <p className="titulo-2 alineacion-texto">AFRICARE</p>
          <p className="texto-2 alineacion-texto">
            Somos una clínica veterinaria de vanguardia dedicada a cuidar de tus
            queridas mascotas <br className="invisible visible-tablet" /> como
            si fueran parte de nuestra propia familia.{' '}
          </p>
          <Accordion className="acordeon">
            <AccordionSummary
              sx={{
                maxHeight: '48px !important',
                minHeight: '48px !important'
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="titulo-1">Misión</p>
            </AccordionSummary>
            <AccordionDetails
              sx={{ paddingTop: '0' }}
              className="texto-acordeon"
            >
              <p className="texto-acordeon">
                Brindar atención médica integral y de calidad, promover la
                prevención y educación, y mantener altos estándares éticos y
                compasivos en todas nuestras actividades.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="acordeon">
            <AccordionSummary
              sx={{
                maxHeight: '48px !important',
                minHeight: '48px !important'
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="titulo-1">Visión</p>
            </AccordionSummary>
            <AccordionDetails
              sx={{ paddingTop: '0' }}
              className="texto-acordeon"
            >
              <p className="texto-acordeon">
                Ser una clínica veterinaria líder en el cuidado y bienestar
                animal, reconocida por su excelencia en la atención médica,
                compromiso con la prevención y educación, y enfoque compasivo y
                ético en el cuidado de las mascotas.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="acordeon">
            <AccordionSummary
              sx={{
                maxHeight: '48px !important',
                minHeight: '48px !important'
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="titulo-1">Compromiso</p>
            </AccordionSummary>
            <AccordionDetails
              sx={{ paddingTop: '0' }}
              className="texto-acordeon"
            >
              <p className="texto-acordeon">
                Nuestro compromiso es proporcionar un servicio profesional,
                compasivo y centrado en el bienestar de tus mascotas, para que
                puedan vivir una vida feliz y saludable.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="acordeon">
            <AccordionSummary
              sx={{
                maxHeight: '48px !important',
                minHeight: '48px !important'
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="titulo-1">Valores</p>
            </AccordionSummary>
            <AccordionDetails
              sx={{ paddingTop: '0' }}
              className="texto-acordeon"
            >
              <p className="texto-acordeon">
                Compasión y cuidado, Ética profesional, Excelencia en la
                atención médica, Educación y prevención, Trabajo en equipo,
                Comunicación efectiva, Responsabilidad social y ambiental,
                Respeto y diversidad.
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{ order: { xs: 1, sm: 1, md: 2 } }}
          style={{ textAlign: 'center' }}
        >
          <img
            src={img}
            alt="Africare"
            style={{ width: '90%', height: 'auto' }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Nosostros
