import React from 'react';

import Container from '@mui/material/Container';


const Error = () => {
    return (
        <Container maxWidth="lg">
            <h1>Error, pagina no encontrada</h1>
        </Container>
    );
};

export default Error;