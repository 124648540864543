import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// *** componentes ***  //
import Header from "../Components/Header";
import Footer from "../Components/Footer";

// *** vistas ***  //
import Index from "../Views/Index";
import Nosotros from "../Views/Nosotros";
import Servicios from "../Views/Servicios";
import Servicio from "../Views/Servicio";
import Testimoniales from "../Views/Testimoniales";
import Derivaciones from "../Views/Derivaciones";
import Asociasiones from "../Views/Asociasiones";
import Contacto from "../Views/Contacto";

// *** otras *** //
import Mensaje from "../Views/Mensaje";
import Error from "../Views/Error";

// *** aviso y terminos *** //
import Terminos from "../Views/AvisoTerminos/Terminos";
import Aviso from "../Views/AvisoTerminos/Aviso";

const Routers = () => (
  <BrowserRouter>
    <Header />

    <Routes>
      {/* vistas */}
      <Route exact path="/" element={<Index />} />
      <Route path="/nosotros" element={<Nosotros />} />
      <Route path="/servicios" element={<Servicios />} />
      <Route path="/servicio/:url" element={<Servicio />} />
      <Route path="/testimonios" element={<Testimoniales />} />
      <Route path="/derivaciones" element={<Derivaciones />} />
      <Route path="/asociaciones" element={<Asociasiones />} />
      <Route path="/contacto" element={<Contacto />} />

      {/* otras */}
      <Route path="/*" element={<Error />} />
      <Route path="/envio-exitoso" element={<Mensaje />} />

      {/* aviso y terminos */}
      <Route path="/terminos-y-condiciones" element={<Terminos />} />
      <Route path="/aviso-de-privacidad" element={<Aviso />} />
    </Routes>

    <Footer />
  </BrowserRouter>
);

export default Routers;
