import React from "react";

const Especialidad = ({ data }) => {
  return (
    <div
      style={{
        border: "solid 1px #EBE5F7",
        borderRadius: "10px",
        height: "100%",
        background: "#fff",
      }}
    >
      <div style={{ padding: "15px" }}>
        <p className="titulo-1">{data.titulo}</p>
        <p className="texto-3">{data.descripcion}</p>
      </div>
    </div>
  );
};

export default Especialidad;
