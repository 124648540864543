const config = {
  apiKey: "AIzaSyBN8hIwMa7RznvpN0skp4xpR0KULvZzByQ",
  authDomain: "africare-2b6eb.firebaseapp.com",
  projectId: "africare-2b6eb",
  storageBucket: "africare-2b6eb.appspot.com",
  messagingSenderId: "720423209462",
  appId: "1:720423209462:web:65dbef7c551d5398ab1fd7",
  measurementId: "G-4G8638JHVX",
};

export default config;
