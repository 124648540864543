import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";

import Especialidades from "../Components/Especialidades";
import Galerias from "../Components/Galerias";

import useConsult from "../Firebase/Hooks/useConsult";

import img6 from "../Images/6.png";
import img6_1 from "../Images/6-1.png";

import galeria1 from "../Images/galeria/1.png";
import galeria2 from "../Images/galeria/2.png";
import galeria3 from "../Images/galeria/3.png";
import galeria4 from "../Images/galeria/4.png";
import galeria5 from "../Images/galeria/5.png";
import galeria6 from "../Images/galeria/6.png";

const Servicios = () => {
  const {
    collection: { especialidades },
  } = useSelector((state) => state.firestore);

  useConsult([
    {
      consulta: "obtenerRealTime",
      collectionName: "especialidades",
    },
  ]);

  const images = [galeria1, galeria2, galeria3, galeria4, galeria5, galeria6];

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img6_1} alt="Africare" />
      ) : (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: "9",
              width: "450px",
              left: "5%",
              top: "15%",
            }}
          >
            <h2 className="titulo-5" style={{ margin: "0" }}>
              Servicios
            </h2>
            <p style={{ marginTop: "0" }} className="texto-2">
              Nuestro enfoque se basa en una atención personalizada, estamos
              listos para escuchar, explicarte los diagnósticos y tratamientos
              de manera clara y brindarte opciones adecuadas para tus mascotas.
            </p>
          </div>
          <img className="imagen_sobrepuesta_2" src={img6} alt="Africare" />
        </div>
      )}
      <Container maxWidth="lg">
        {window.innerWidth <= 990 && (
          <div
            style={{ textAlign: "center", maxWidth: "500px", margin: "auto" }}
          >
            <h2 className="titulo-5" style={{ margin: "0" }}>
              Servicios
            </h2>
            <p style={{ marginTop: "0" }}>
              Nuestro enfoque se basa en una atención personalizada, estamos
              listos para escuchar, explicarte los diagnósticos y tratamientos
              de manera clara y brindarte opciones adecuadas para tus mascotas.
            </p>
          </div>
        )}
        <p className="titulo-1">NUESTROS SERVICIOS</p>
        <p className="titulo-2">Especialidades</p>

        <div className="contenedor-1" style={{ marginTop: "50px" }}>
          {especialidades &&
            especialidades.docs.map((especialidad) => (
              <Especialidades data={especialidad} />
            ))}
        </div>

        <section className="separacion" style={{ marginTop: "20px" }}>
          <p className="titulo-1">NUESTROS CONSENTIDOS</p>
          <p className="titulo-2">Écha un vistazo</p>
          <Galerias images={images} />
        </section>
      </Container>
    </Fragment>
  );
};

export default Servicios;
