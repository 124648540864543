import React, { forwardRef } from 'react'

import TextField from '@mui/material/TextField'
import { createTheme, styled } from '@mui/material/styles'
import { IMaskInput } from 'react-imask'
import Input from '@mui/material/Input'
import InputBase from '@mui/material/InputBase'
import MenuIcon from '@mui/icons-material/Menu'

// inputs
// diseño para el textArea del mensaje
export const ValidationTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#F8F8F8',
    fontWeight: '400 !important'
  },
  '& .MuiInputLabel-root': {
    top: '-20px',
    fontWeight: '400 !important',
    // fontFamily: 'Poppins',
    color: '#002F86',
    fontSize: '20px'
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F8F8F8',
    fontSize: 16,
    width: '-webkit-fill-available',
    padding: '10px'
  },
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 2
  },
  '& input::placeholder': {
    fontWeight: '400 !important'
    // fontFamily: 'Poppins'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid #707070'
    },
    '& fieldset legend': {
      display: 'none'
    },
    '&:hover fieldset': {
      borderColor: 'grey'
    }
  },
  '& .css-wgai2y-MuiFormLabel-asterisk': {
    display: 'none'
  }
}))

// diesño para el input
export const ValidationInput = styled(Input)(({ theme }) => ({
  '&.MuiInputBase-root': {
    borderRadius: 4,
    border: '2px solid #707070',
    backgroundColor: '#F8F8F8',
    fontSize: '17px',
    padding: '5px 10px',
    fontWeight: '400 !important',
    marginTop: '0'
  },
  '&.MuiInputBase-root::before': {
    display: 'none'
  },
  '&.MuiInputBase-root::after': {
    display: 'none'
  },
  '&.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '0px',
    borderColor: 'grey'
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: 'black'
    }
  }
}))

// selceted style
export const SelectInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F8F8F8',
    border: '2px solid #707070',
    color: '#9d9d9d',
    fontSize: 16,
    fontWeight: '400 !important',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#1976d2'
    }
  }
}))

// diseño para el input del telefono
export const Telefono = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={'(#00) 000-0000'}
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      placeholder={props.placeholder}
    />
  )
})

// header
export const headerTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#ffffff00',
          position: 'relative',
          justifyContent: 'center',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          '& .MuiToolbar-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '400 !important'
          }
        })
      }
    }
  }
})

// Burger color negro
export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: 'black'
}))
