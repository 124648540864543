import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function createMarkup(descripcion) {
  return { __html: descripcion }
}

function Especialidades({ data }) {
  return (
    <div class="card">
      {window.innerWidth <= 992 ? (
        <Link
          to={`/servicio/${data.url_redireccion}`}
          style={{
            height: '75%',
            position: 'absolute',
            maxWidth: '80%',
            width: 'inherit',
            objectFit: 'contain'
          }}
        >
          <img
            src={data.imagenes.imagenes[0]}
            style={{
              height: '100%',
              position: 'absolute',
              maxWidth: '100%',
              width: 'inherit',
              objectFit: 'contain'
            }}
          />
        </Link>
      ) : (
        <Fragment>
          <img
            src={data.imagenes.imagenes[0]}
            style={{
              height: '75%',
              position: 'absolute',
              maxWidth: '80%',
              width: 'inherit',
              objectFit: 'contain'
            }}
          />
          <Link
            style={{ textDecoration: 'none' }}
            to={`/servicio/${data.url_redireccion}`}
          >
            <div className="icon-container">
              <div>
                <h2 className="titulo-3" style={{ margin: '0' }}>
                  {data.nombre}
                </h2>
                <div
                  className="texto-tarjeta"
                  dangerouslySetInnerHTML={createMarkup(
                    data.descripcion_externa
                  )}
                ></div>
              </div>
              <Link
                className="especialidades-redireccion"
                to={`/servicio/${data.url_redireccion}`}
              >
                Ver más
              </Link>
            </div>
          </Link>
        </Fragment>
      )}
    </div>
  )
}

export default Especialidades
