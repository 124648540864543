import React, { Fragment } from 'react'

import Container from '@mui/material/Container'

import Galerias from '../Components/Galerias'

import img10 from '../Images/10.png'
import img10_1 from '../Images/10-1.png'
import img3 from '../Images/3.png'

import galeria1 from '../Images/galeria/1.png'
import galeria2 from '../Images/galeria/2.png'
import galeria3 from '../Images/galeria/3.png'

const Asociasiones = () => {
  const images = [galeria1, galeria2, galeria3]

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img10_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img10} alt="Africare" />
      )}

      <Container
        sx={{ maxWidth: { xs: '350px', sm: '700px', md: 'lg', lg: 'lg' } }}
      >
        <section>
          <h2 className="titulo-5" style={{ margin: '0', textAlign: 'center' }}>
            Asociaciones
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '50px 0'
            }}
          >
            <img
              src={img3}
              alt="HerGon"
              style={{ width: '100%', maxWidth: '600px' }}
            />
          </div>
          <p
            className="texto-2"
            style={{ textAlign: 'center', maxWidth: '1000px', margin: 'auto' }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </section>

        <section className="separacion" style={{ marginTop: '20px' }}>
          <Galerias images={images} />
        </section>
      </Container>
    </Fragment>
  )
}

export default Asociasiones
