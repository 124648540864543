import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { Carousel } from '@trendyol-js/react-carousel'

import Container from '@mui/material/Container'

// icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useConsult from '../Firebase/Hooks/useConsult'

import img7 from '../Images/7.png'
import img7_1 from '../Images/7-1.png'
import Testimonios from '../Components/Testimonios'

const Testimoniales = () => {
  const {
    collection: { testimonios }
  } = useSelector((state) => state.firestore)

  const carrusel =
    window.innerWidth <= 550 ? 1 : window.innerWidth <= 900 ? 2 : 3

  useConsult([
    {
      consulta: 'obtenerRealTime',
      collectionName: 'testimonios'
    }
  ])

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img7_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img7} alt="Africare" />
      )}

      <Container
        sx={{
          maxWidth: { xs: '370px', sm: '700px', md: 'lg', lg: 'lg' },
          padding: '20px 0 40px 0'
        }}
      >
        <section>
          <h2 className="titulo-5" style={{ margin: '0', textAlign: 'center' }}>
            Testimonios
          </h2>
          <p
            className="texto-2"
            style={{ textAlign: 'center', maxWidth: '600px', margin: 'auto' }}
          >
            Nos aseguramos de que nuestros pacientes tengan un espacio cómodo y
            seguro mientras recibe la atención médica necesaria.
          </p>

          <div className="contenedor-centro" style={{ margin: '20px' }}>
            {testimonios && (
              <Carousel
                useArrowKeys={true}
                rightArrow={
                  <div className="flechas-carrusel">
                    <ArrowForwardIosIcon />
                  </div>
                }
                leftArrow={
                  <div className="flechas-carrusel">
                    <ArrowBackIosNewIcon />
                  </div>
                }
                show={carrusel}
                slide={1}
                transition={0.5}
              >
                {testimonios.docs.map((testimonio) => (
                  <div className="item-centro">
                    <Testimonios data={testimonio} />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </section>
      </Container>
    </Fragment>
  )
}

export default Testimoniales
